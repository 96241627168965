
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from 'react';

import Head from 'next/head';

// Styles
import styles from './404.module.scss';

const Page404 = () => (
  <div>
    <Head>
      <title>Page Not Found</title>
      <link rel="shortcut icon" href="/static/favicon.ico" sizes="any" />
      <link rel="shortcut icon" href="/static/favicon.svg" />
      <meta name="robots" content="noindex" />
    </Head>
    <main className={styles.main}>
      <div className={styles.content}>
        <p>
          We are sorry but this job is no longer vacant and our client
          successfully hired a new talent.
        </p>
        <p className={styles.message}>
          If you’re not a Lionstep-Candidate already you can sign-up{' '}
          <a
            className={styles.link}
            href="https://candidate.lionstep.com/signin"
          >
            here
          </a>{' '}
          to get other interesting job opportunities.
        </p>

        <p className={styles.message}>We wish you a great day!</p>

        <p className={styles.farewell}>Your Lionstep-Team</p>
        <img
          className={styles.logo}
          src="/static/lionstep-logo.svg"
          alt="Lionstep"
        />
      </div>
    </main>
  </div>
);

export default Page404;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  